import * as React from 'react';
import InnerFrame from './InnerFrame';
import styled from 'styled-components';

const Frame = styled.div`
  ::before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    display: block;
    background-image: url('https://images.unsplash.com/photo-1536257104079-aa99c6460a5a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80');
    background-size: cover;
    filter: blur(30px) grayscale(40%) brightness(40%);
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    ::before {
      background-image: none;
    }
  }
`;

const OuterFrame: React.FC = () => {
  return (
    <Frame>
      <InnerFrame />
    </Frame>
  );
};

export default OuterFrame;
