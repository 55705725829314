import * as React from 'react';
import { Flex, Box } from '@rebass/grid';
import ProfilePicture from './Profile.png';
import styled from 'styled-components';

const Picture = styled.img`
  border-radius: 40%;
  box-shadow: 0 0 5px 5px #353535;
  max-height: 35vh;
`;

const Name = styled.h1`
  font-weight: 100;
  font-size: calc(2em + 1.5vh);
  text-shadow: 1px 1px #000000;
`;

const Description = styled.p`
  text-align: center;
  font-weight: 100;
  font-size: calc(0.9em + 1vh);
`;

const Link = styled.a`
  :link,
  :visited,
  :hover,
  :active {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
  }
`;

const Profile: React.FC = () => {
  return (
    <Flex
      flex="1 1 auto"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-around"
    >
      <Box pt={2}>
        <Picture src={ProfilePicture} alt="Janis Reber" />
      </Box>
      <Name>Janis Reber</Name>
      <Description>
        Principal Software Engineer and Project Lead at&nbsp;
        <Link
          href="https://www.novatec-gmbh.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Novatec
        </Link>
        .
      </Description>
    </Flex>
  );
};

export default Profile;
