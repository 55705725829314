import * as React from 'react';
import styled from 'styled-components';
import { Box, Flex } from '@rebass/grid';

const LinkBox = styled(Box)`
  padding: 0 0.5em;
  border-left: 1px solid #fff;

  :first-of-type {
    padding-left: 0;
    border-left: none;
  }
`;

const Link = styled.a`
  :link,
  :visited,
  :hover,
  :active {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
  }
`;

const Credits = styled.span`
  font-weight: lighter;
  font-size: calc(0.1em + 1vh);
`;

const CommonLinks: React.FC = () => {
  const imprintOnClick = (href: string) => (event: any) => {
    // if ctrl or meta key are held on click, allow default behavior of opening link in new tab
    if (event.metaKey || event.ctrlKey) {
      return;
    }
    event.preventDefault();
    window.history.pushState({}, '', href);
    // communicate to Routes that URL has changed
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  return (
    <Flex
      flexDirection="row"
      mb="0.1em"
      justifyContent="space-between"
      alignItems="baseline"
      width="100%"
    >
      <Flex flexWrap="nowrap">
        <Credits>
          Photo by{' '}
          <Link href="https://unsplash.com/photos/62t_kKa2YlA">
            Mohammad Alizade
          </Link>{' '}
          on <Link href="https://unsplash.com/">Unsplash</Link>
        </Credits>
      </Flex>
      <Flex flexWrap="nowrap">
        <LinkBox>
          {window.location.pathname === '/impressum' ? (
            <Link href="/" onClick={imprintOnClick('/')}>
              &lt; back &gt;
            </Link>
          ) : (
            <Link href="/impressum" onClick={imprintOnClick('/impressum')}>
              &lt; impressum &gt;
            </Link>
          )}
        </LinkBox>
        <LinkBox>
          <Link href="https://nextcloud.familie-reber.de" target="_blank">
            &lt; nextcloud &gt;
          </Link>
        </LinkBox>
      </Flex>
    </Flex>
  );
};

export default CommonLinks;
