import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Flex } from '@rebass/grid';
import Profile from './components/Profile/Profile';
import SocialProfiles from './components/SocialProfiles/SocialProfiles';
import CommonLinks from './components/CommonLinks/CommonLinks';
import Imprint from './components/Imprint/Imprint';

const Frame = styled(Flex)`
  position: relative;
  height: 100vh;

  ::before {
    content: '';
    position: fixed;
    left: 5vw;
    top: 5vh;
    width: 90vw;
    height: 90vh;
    z-index: -1;
    display: block;
    background-image: url('https://images.unsplash.com/photo-1536257104079-aa99c6460a5a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80');
    background-size: cover;
    filter: contrast(90%) grayscale(40%) brightness(70%);
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    ::before {
      background-image: none;
      background-color: #636363;
    }
  }
  @supports (-ms-ime-align: auto) {
    /* IE Edge 12+ CSS styles go here */
    ::before {
      filter: blur(2px) grayscale(40%) brightness(70%);
    }
  }
`;

const InnerFrame: React.FC = () => {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  useEffect(() => {
    const onLocationChange = () => {
      // update path state to current window URL
      setCurrentPath(window.location.pathname);
    };
    window.addEventListener('popstate', onLocationChange);
    // clean up event listener
    return () => {
      window.removeEventListener('popstate', onLocationChange);
    };
  }, []);
  return (
    <Frame
      px="5vw"
      py="5vh"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-around"
    >
      {currentPath === '/impressum' ? (
        <Imprint />
      ) : (
        <>
          <Profile />
          <SocialProfiles />
        </>
      )}
      <Box flex="1 1 auto" />
      <CommonLinks />
    </Frame>
  );
};

export default InnerFrame;
