import * as React from 'react';
import { Flex } from '@rebass/grid';
import GithubImage from './img/Github.png';
import LinkedInImage from './img/LinkedIn.png';
import NovaTecImage from './img/NovaTec.png';
import LinkImage from './LinkImage/LinkImage';

const SocialProfiles: React.FC = () => {
  return (
    <Flex justifyContent="space-evenly" alignItems="center">
      <LinkImage
        image={NovaTecImage}
        link="https://www.novatec-gmbh.de/autor/janis-reber/"
        alt="NovaTec Blog Author Profile"
      />
      <LinkImage
        image={GithubImage}
        link="https://github.com/janis91"
        alt="Github Profile"
      />
      <LinkImage
        image={LinkedInImage}
        link="https://www.linkedin.com/in/janis-reber"
        alt="LinkedIn Profile"
      />
    </Flex>
  );
};

export default SocialProfiles;
