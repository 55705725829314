import * as React from 'react';
import { Box } from '@rebass/grid';
import styled from 'styled-components';

interface IOwnProps {
  link: string;
  image: string;
  alt?: string;
}

const Logo = styled.img`
  max-width: 7.5vh;
`;

const LinkImage: React.FC<IOwnProps> = props => {
  return (
    <Box mx={3}>
      <a href={props.link} target="_blank" rel="noopener noreferrer">
        <Logo src={props.image} alt={props.alt} />
      </a>
    </Box>
  );
};

export default LinkImage;
